import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const ModelPage = (props) => (
  <Gallery
    metaTitle="Models | c360"
    title="Models"
    dashboardName="model-page"
    pageComponent={props}
  />
);

export default ModelPage;
